.auth .modal-content{
    padding: 2rem;
    margin-top: 3rem;
}
.modal.show .modal-dialog{
    margin-top: 2rem;
}
.login-form .title,
.signup-form .title{
   margin-bottom: 1rem;
   text-align: center;
   font-size: 1.8rem;
   position: relative;
}
.login-form .title::after,
.signup-form .title::after{
   content: '';
   width: 13rem;
   height: 2px;
   background-color: #000;
   position: absolute;
   bottom: 0;
   left: 50%;
   transform: translate(-50%, 8px);
}
.signup-form .title::after{
    width: 9rem;
}
.login-form,
.signup-form {
    padding: 2rem 0;
}
.login-form .login-btn,
.signup-form .signup-btn{
    background-color: var(--primary-color);
    margin-top: 1rem;
    width: 100%;
    padding: 12px;
}
.login-form .login-btn:hover,
.signup-form .signup-btn:hover{
    background-color: var(--primary-color);
    opacity: .8;
}
.signup,
.login{
    background: none;
    margin-top: 1rem;
    width: 100%;
    padding: 12px;
    color: #000;
    border: none;
}
.signup:hover,
.login:hover{
    background-color: #dedede;
    color: #000;
}
.signup-btn span {
    margin-left: 1rem;
}
.forget{
    background: none;
    border: none;
    color: blue;
    padding: 0;
    margin-top: 5px;
}
.forget:hover{
    color: rgba(0, 0, 255, 0.57);
}
.showPassword{
    position: absolute;
    top: 2.6rem;
    cursor: pointer;
}
.en .showPassword{
    right: 25px;
    left: auto;
}
.ar .showPassword{
    left: 25px;
    right: auto;
}
.banner {
  padding: 2rem;
  margin-bottom: 4rem;
  text-align: center;
  position: relative;
}
.banner h1{
  margin-bottom: 4rem;
}
.banner h1::after{
  content: url("../../../assets/arrow.png");
  position: absolute;
  left: 77px;
  top: 35px;
}
.banner img {
  max-width: 100%;
  height: auto;
  margin-bottom: 4rem;
  width: 100%;
}
  
.banner .viewAll {
  background-color: var(--primary-color);
  text-decoration: none;
  color: #fff;
  margin-top: 12px;
  width: fit-content;
  padding: 9px 22px;
  border-radius: 8px;
}
@media only screen and (max-width: 500px) {
  .banner {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .banner h1 {
    margin-bottom: 2rem;
    font-size: 22px;
  }
  .banner h1::after{
    content: '';
  }
  .banner img {
    margin-bottom: 2rem;
  }
  
}
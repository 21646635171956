.style-up{
    padding: 2rem;
    margin-top: 9rem;
}
.style-up .sheet-title{
    color: var(--primary-color);
}
.style-up .tabs-div {
    padding-bottom: 5px;
    /* border-bottom: 1px solid #e8eaeb; */
    display: flex;
    gap: 12px;
    width: fit-content;
}
.style-up p {
    padding: 5px 16px;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
}
@media only screen and (max-width: 500px) {
    .style-up img{
        width: 100%;
        height: auto;
    }
}
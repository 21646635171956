.shop-by-category{
  padding: 2rem;
  margin-top: 2rem;
}
.shop-by-category .categories {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.shop-by-category h4{
  color: var(--primary-color);
  font-size: 25px;
  font-weight: 500 !important;
  text-transform: capitalize;
  margin-bottom: 2rem;
}
.shop-by-category h4::after{
    content: url("../../../assets/Star1.png");
    position: absolute;
    top: -18px;
}
.shop-by-category h4::before{
    content: url("../../../assets/Star2.png");
    position: absolute;
    top: 5px;
}
.en .shop-by-category h4::after{
    left: 14rem;
}
.en .shop-by-category h4::before{
    left: 15rem;
}
.ar .shop-by-category h4::after{
    right: 14rem;
}
.ar .shop-by-category h4::before{
    right: 15rem;
}
.shop-by-category .cate{
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 5px #dedede7a;
}
.shop-by-category .cate .desc{
  position: absolute;
  background-color: #0e3a5d78;
  top: 50%;
  left: 50%;
  max-width: 80%;
  color: #ffffff;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: 5px;
  text-align: center;
  text-wrap: nowrap;
  overflow: hidden;
}
.shop-by-category .cate:hover .desc{
  background-color: var(--primary-color);
  transition: .5s ease-in-out;
}

.shop-by-category .cate img{
  min-height: 326px !important;
  height: 326px !important;
}
.shop-by-category .cate:hover img{
  transform: scale(1.1);
  transition: transform .5s ease-in-out;
}
.shop-by-category .desc p{
  font-size: 22px;
  margin-bottom: .5rem;
}
.shop-by-category .desc a{
  background: none;
  font-size: 16px;
  text-decoration: underline;
}
  /* .shop-by-category img {
    max-width: 300px;
    height: 300px;
    border-radius: 10px;
  } */
  .shop-by-category a{
    padding: 8px 20px;
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 12px;
  }
  @media only screen and (max-width: 500px) {
    .shop-by-category {
      padding: 1rem;
    }
    .shop-by-category h4 {
      font-size: 22px;
    }
    .shop-by-category h4::before,
    .shop-by-category h4::after{
      content: '';
    }
    .shop-by-category .desc p {
      font-size: 16px;
    }
    .shop-by-category .cate .desc {
      padding: 1rem 6px;
    }
    .shop-by-category .aos-init{
      opacity: 1 !important;
      transform: none !important;
    }
    .shop-by-category .cate img {
      min-height: 200px !important;
      height: 200px !important;
  }
  }
.profile{
    padding: 2rem;
    margin-top: 9rem;
}
.profile .profile-details .logout{
    background-color: #fff;
    margin-top: 3rem;
    color: var(--primary-color);
    width: 100%;
    border-radius: 8px;
}
.profile .profile-details .logout:hover{
    color: #000;
    background-color: #dedede;
}
.profile .profile-details{
    background-color: var(--primary-color);
    color: #fff;
    padding: 2.5rem 4rem;
    border-radius: 16px;
    font-size: 18px;
}
.profile .details{
    padding: 0 2rem;
}
.profile .tabs{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 3rem;
}
.profile .tabs .tab{
    background-color: #dedede;
    color: #000;
    padding: 12px 16px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    margin: 0;
}
.profile .tabs .tab:hover{
    background-color: #8b8b8b;
    color: #fff;
}
.profile .tabs .tab.active{
    background-color: var(--primary-color);
    color: #fff;
}
.profile .profile-pic .pic{
    position: relative;
}
.profile .profile-pic img{
    width: 110px;
    height: 110px;
}

.image-placeholder {
    position: relative;
    max-width: 110px;
}
.image-placeholder .avatar-edit {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0; 
    z-index: 1;
}
.image-placeholder .avatar-edit input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.image-placeholder .avatar-edit input + label {
          display: inline-block;
          width: 34px;
          height: 34px;
          margin-bottom: 0;
          border-radius: 100%;
          background: #FFFFFF;
          border: 1px solid transparent;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
          cursor: pointer;
          font-weight: normal;
          transition: all .2s ease-in-out; 
}
.image-placeholder .avatar-edit input + label:hover {
            background: #f1f1f1;
            border-color: #d6d6d6; 
}
.image-placeholder .avatar-edit input + label:after {
            content: "\F4C9";
            font-family: 'FontAwesome';
            color: #757575;
            position: absolute;
            top: 6px;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto; 
}
.image-placeholder .avatar-preview {
      width: 110px;
      height: 110px;
      position: relative;
      border-radius: 30px;
}
.image-placeholder .avatar-preview > div {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden; 
}
.image-placeholder .avatar-preview > div img {
          height: 100%;
          width: 100%;
}
.profile .orders .order{
    background-color: #F8F8F8;
    
}
.categories-home{
  padding: 20px;
}
.categories-home .swiper-slide{
  width: auto!important;
}
.categories-home .mySwiper{
  direction: ltr;
}
.categories-home .mySwiper .swiper-slide{
  text-align: center;
}
.categories-home .mySwiper .swiper-slide a{
  border-radius: 8px;
    color: #fff;
    font-size: 19px;
    text-decoration: none;
    padding: 16px;
    line-height: 3;
    text-wrap: nowrap;
}
@media only screen and (max-width: 500px) {
  .categories-home{
    padding: 20px 0;
  }
  .categories-home .mySwiper .swiper-slide a{
      font-size: 14px;
      padding: 12px;
  }
}
.categories-home .mySwiper .swiper-slide:nth-child(odd) a{
  background-color: var(--primary-color);
}
.categories-home .mySwiper .swiper-slide:nth-child(even) a{
  background-color: #2e5e84db;
}
.products{
  padding: 2rem;
  margin-top: 9rem;
}
/* .products h1{
  color: var(--primary-color);
  font-weight: 400 !important;
  text-transform: capitalize;
  margin-bottom: 4rem;
  text-align: center;
}

.products h1::before{
  content: url("../../assets/arrow.png");
  position: absolute;
  left: 77px;
  top: -5px;
  rotate: 20deg;
} */
.old-price{
  font-size: 16px;
  text-decoration: line-through;
}
.products .cate{
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 5px #dedede7a;
  min-height: 326px !important;
  height: 326px !important;
}
.products .cate .desc{
  position: absolute;
  background-color: #0e3a5d78;
  bottom: 0%;
  left: 0;
  color: #ffffff;
  width: 100%;
  height: 50%;
  padding: 1rem 2rem;
  text-wrap: nowrap;
}

.products .cate:hover img{
  transform: scale(1.1);
  transition: transform .5s ease-in-out;
}
.products .desc h4{
  font-size: 22px;
  margin-bottom: 3px;
  text-wrap: wrap
}
.products .desc p{
  font-size: 16px;
  margin: 0;
  text-wrap: wrap
}

  /* .products img {
    max-width: 300px;
    height: 300px;
    border-radius: 10px;
  } */
  .products a{
    padding: 8px 20px;
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 12px;
  }
  @media only screen and (max-width: 500px) {
    .products {
      padding: 1rem;
      margin-top: 5rem;
    }
    .products .cate .desc {
      padding: .5rem;
    }
    .products .desc h4 {
      font-size: 18px;
      text-wrap: nowrap;
      overflow-y: auto;
    }
    .products .desc p {
      font-size: 14px;
    }  
  }
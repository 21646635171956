.quality{
    margin: 2rem 0;
    padding: 2rem 0;
}
.quality h1{
    position: relative;
    color: #fff;
    padding: 3rem 3rem 0;
    font-weight: 600;
}
.quality h1::after{
    content: url("../../../assets/arrow.png");
    position: absolute;
    left: 77px;
    top: -50px;
}
.quality img{
    width: 60%;
}
.footer{
    background-color: var(--primary-color);
    color: #fff;
}
.footer .links a{
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}
.footer .links a.active{
    color: #b96464 !important;
} 
.apps .icons p{
    margin-bottom: 8px;
}
.apps .icons i{
    border: 1px solid;
    border-radius: 8px;
    padding: 4px;
}
.apps a{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    margin-right: 12px;
}
.social .icons img{
    height: 18px;
}
.social .visa img{
    width: 37px;
    height: 25px;
}
.social .icons{
    margin-top: 24px;
    margin-bottom: 16px;
    text-align: center;
}
.social .icons a{ 
    margin-left: 8px;
    /* background: #111; */
    padding: 7px;
    border-radius: 5px;
}
.footer .power{
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    line-height: 19.2px;
}
.ar .footer .qr{
    text-align: left;
}
.en .footer .qr{
    text-align: right;
}
.footer .qr div{
    justify-content: end;
}
@media only screen and (max-width: 500px) {
    .mt-5 {
        margin-top: 1rem !important;
    }
    .footer{
        text-align: center;
    }
    .col-12{
        margin-bottom: 2rem;
    }
    .col-12:nth-child(2){
        margin-bottom: 0;
    }
    .ar .footer .qr,
    .en .footer .qr {
        text-align: center;
    }
    .qr{
        display: none;
    }
    .footer .qr div {
        justify-content: center;
    }
}
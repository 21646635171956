.contact{
  padding: 2rem;
  margin-top: 9rem;
}
.contact .send{
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 8px;
}
.contact .contact-whats{
  color: var(--primary-color);
  background-color: #fff;
  border-radius: 8px;
}
.getInTouch{
  color: var(--primary-color)
}
/* .aboutUs h1{
  color: var(--primary-color);
  font-weight: 400 !important;
  text-transform: capitalize;
  margin-bottom: 4rem;
  text-align: center;
}

.aboutUs h1::before{
  content: url("../../assets/arrow.png");
  position: absolute;
  left: 77px;
  top: -5px;
  rotate: 20deg;
} */
@media only screen and (max-width: 500px) {
  .contact {
    padding: 1rem;
    margin-top: 7rem;
  }
  .contact .row div:nth-of-type(3){
    margin-top: 2rem;
  }
  .login-form, 
  .signup-form {
    padding: 1rem 0;
  }
}
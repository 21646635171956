.pdf{
    margin: 8rem 0;
}
.pdf h1{
    position: relative;
    color: #fff;
    font-weight: 600;
}
.pdf h1::after{
    content: url("../../../assets/arrow.png");
    position: absolute;
    top: -50px;
}
.en .pdf h1::after{
    right: 0;
}
.ar .pdf h1::after{
    left: 0;
    transform: rotateY(180deg);
}
.pdf img{
    width: 130px;
}
.pdf .pdf-img{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.pdf .pdf-img a{
    width: fit-content;
    color: var(--primary-color);
    background-color: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    text-decoration: none;
}
@media only screen and (max-width: 500px) {
    .pdf {
        margin: 3rem 0;
    }
    .pdf .custom-div {
        padding: 1rem !important;
    }
    .pdf h1{
        font-size: 20px;
    }
    .pdf h1::after {
        content: '';
    }
    .pdf img {
        width: 70px;
    }
    .pdf a{
        padding: 8px;
        font-size: 14px;
        text-align: center;
    }
    .pdf .pdf-img {
        align-items: center;
    }
}
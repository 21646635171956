.navbar{
    background-color: var(--primary-color);
    position: fixed;
    padding: 2.23rem 1rem;
    top: 0;
    width: 100%;
    z-index: 999;
}  
.navbar a{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 6px 20px;
    line-height: normal
} 
.navbar a:hover{
    color: #b6b6b6;
}
.navbar a.active{
    color: #b96464 !important;
} 
.navbar-collapse {
    justify-content: flex-end;
}
.navbar .cart-num{
    background-color: #b96464;
    color: #fff;
    position: absolute;
    border-radius: 50%;
    right: -12px;
    top: -12px;
    padding: 0 6px;
    font-size: 14px;
}
.nav-mobile-logo{
    display: none;
}
.navbar-toggler-icon{
    background-image: url('../../assets/Hamburger.png')!important;
    border-color: #fff;
}
@media only screen and (max-width: 500px) {
    .navbar {
        padding: .75rem 0;
        top: -2px;
    }
    .navbar .col-md-5{
        padding: 0;
    }
    .navbar-collapse{
        position: absolute;
        background: var(--primary-color);
        top: 63px;
        left: 0;
        width: 100%!important;
    }
    .navbar-collapse a {
        margin-bottom: 8px;
    }
    .nav-logo{
        display: none;
    }
    .nav-mobile-logo{
        display: block;
    }
}
.product{
    margin-top: 12rem;
    margin-bottom: 7rem;
}
.product .category{
    font-size: 18px;
    background-color: var(--primary-color);
    width: fit-content;
    color: #fff;
    border-radius: 5px;
    padding: 4px 8px;
}
.product .price{
    font-size: 20px;
}
.product .description{
    margin-bottom: 2rem;
}
.product .variant-values{
    gap: 20px 50px;
    display: grid;
    grid-template-columns: auto auto auto;
}
.product .addToCart{
    background-color: var(--primary-color);
    color: #fff;
    text-decoration: none;
    width: 100%;
    font-size: 20px;
    padding: 12px 50px;
    border: 1px solid var(--primary-color);
}
.product .addToCart:hover{
    background: none;
    color: var(--primary-color);
}
.product .buy{
    background: var(--primary-color);
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 8px;
}
.product .amounts span{
    font-size: 22px;
}
.product .main-img{
    width: 400px!important;
    height: 400px!important;
}
.product .swiper-slide img{
    width: 90px!important;
    height: 90px!important;
}
.product .swiper-pagination{
    position: relative;
    margin-top: 1.5rem;
}
@media only screen and (max-width: 768px) {
    .product .main-img{
        width: 280px!important;
        height: 280px!important;
    }
}
@media only screen and (max-width: 500px) {
    .product {
        margin-top: 8rem;
        margin-bottom: 4rem;
    }   
    .paths{
        display: none;
    } 
    .product .main-img{
        width: 200px!important;
        height: 200px!important;
    }
    .product .swiper-slide img{
        width: 60px!important;
        height: 60px!important;
    }
    .products .cate{
        min-height: 200px !important;
        height: 200px !important;
    }
}
.checkout_page{
    margin-top: 12rem;
}
.checkout_page input,
.checkout_page select{
    margin-top: 6px;
}
.sec_head {
    position: relative;
}
.sec_head:before {
    content: "";
    background: var(--primary-color);
    width: 100px;
    height: 1px;
    position: absolute;
    bottom: -5px;
    transform: translateX(0);
}
.en .sec_head:before{
    left: 0;
}
.ar .sec_head:before{
    right: 0;
}
.checkout_page .payment-method{
    background: #26847d;
    padding: 20px 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.payment-method h5, 
.cont-pay-dts h5, 
.coupon-code h5 {
    color: #fff;
    margin-bottom: 15px;
}
.form-coupon .form-group {
    position: relative;
}
.form-coupon .form-control {
    background: #fff;
    border: 0;
    border-radius: 35px;
    height: 50px;
    color: #505153;
}
.form-coupon .btn-site {
    height: 40px;
    position: absolute;
    top: 5px;
    border-radius: 35px;
    background-color: #26847d;
}
.ar .form-coupon .btn-site {
    left: 4px;
}
.en .form-coupon .btn-site {
    right: 4px;
}
.payment-method .list-pay div{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 12px;
}
.ar .payment-method .list-pay div svg,
.ar .payment-method .list-pay div img{
    margin-left: 12px;
}
.en .payment-method .list-pay div svg,
.en .payment-method .list-pay div img{
    margin-right: 12px;
}
.payment-method .list-pay div label{
    color: #fff;
}
.cont-pay-dts,
.checkout_page .coupon-code{
    background: var(--primary-color);
    padding: 20px 15px;
    border-radius: 10px;
}
.cont-pay-dts .send_form{
    background-color: #fff;
    width: 100%;
    display: block;
    margin-top: 18px;
    height: 40px;
    font-size: 15px;
    padding: 12px 25px;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    color: var(--primary-color);
    border-radius: 30px;
}
.cont-pay-dts div{
    color: #fff;
    display: flex;
    justify-content: space-between;
}
.address label{
    color: #666;
    font-size: 18px;
}
.product-cart{
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    border-bottom: 1px solid #dedede;
    padding-bottom: 1.5rem;
}
.form-coupon .btn-site {
    height: 40px;
    position: absolute;
    top: 5px;
    border-radius: 35px;
    background-color: var(--primary-color);
}
 .form-coupon .btn-danger {
    height: 40px;
    position: absolute;
    top: 5px;
    border-radius: 35px;
    color: #fff;
    background-color: var(--bs-btn-bg);
    padding: 10px 15px;
}
.ar .form-coupon .btn-site,
.ar .form-coupon .btn-danger {
    left: 4px;
}
.en .form-coupon .btn-site,
.en .form-coupon .btn-danger {
    right: 4px;
}
.submit-order{
    background-color: #fff!important;
    width: 100%;
    margin-top: 18px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.submit-order:hover{
    border-color: #fff;
    color: #fff;
    background-color: var(--primary-color)!important;
}
@media only screen and (max-width: 500px) {
    .tot-pri{
        text-align: start;
    }
    input{
        font-size: 16px!important;
    }
    .address{
        margin-bottom: 1.5rem;
    }
}